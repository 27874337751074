var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":true,"color":_vm.color,"timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbar_msg != null)?_c('span',[_vm._v(_vm._s(_vm.snackbar_msg))]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-row',{staticClass:"bradcrumb"},[_c('router-link',{staticClass:"root",attrs:{"to":{ name: 'management-dashboard' }}},[_vm._v(" Dashboard ")]),_c('span',{staticClass:"child"},[_vm._v("/ Titles ")])],1),_c('br'),_c('section',{staticClass:"bg-color-"},[_c('div',{staticClass:"container"},[[_c('v-card',[_c('v-card-title',[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"persistent-hint":"","label":"Orgnaization","items":_vm.org_list,"item-text":"orgName","item-value":"orgid"},on:{"change":function($event){return _vm.getdetails()}},model:{value:(_vm.orgid),callback:function ($$v) {_vm.orgid=$$v},expression:"orgid"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('download-excel',{attrs:{"data":_vm.details_list,"fields":_vm.single_,"worksheet":"Titles Data","name":"Titles Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"20px"},attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}])},[_c('span',[_vm._v("Import to Excel")])])],1)]],2),[(_vm.circular)?_c('div',{staticClass:"text-center"},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"25","color":"#393e46"}},[_c('strong',{staticStyle:{"color":"#FFF"}},[_vm._v("Loading...")])])],1):_vm._e()],_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.details_list,"search":_vm.search},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_c('b',[_vm._v("TotalAmount: "+_vm._s(_vm.gradndtotal))])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-currency-inr")]),_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.cost))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-currency-inr")]),_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.price))])]}},{key:"item.totalprice",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-currency-inr")]),_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.totalprice))])]}}])})],2)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }