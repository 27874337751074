<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'management-dashboard' }" class="root">
        Dashboard
      </router-link>
      <span class="child">/ Titles </span> </v-row
    ><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <v-card>
            <v-card-title>
              <v-autocomplete
                persistent-hint
                label="Orgnaization"
                :items="org_list"
                item-text="orgName"
                item-value="orgid"
                v-model="orgid"
                style="margin-top:10px;"
                @change="getdetails()"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <template>
                <download-excel
                  :data="details_list"
                  :fields="single_"
                  worksheet="Titles Data"
                  name="Titles Data.xls"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        size="40"
                        left
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left:20px;"
                        >mdi-file-excel</v-icon
                      >
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
            <template>
              <div class="text-center" v-if="circular">
                <v-progress-linear indeterminate height="25" color="#393e46">
                  <strong style="color:#FFF;">Loading...</strong>
                </v-progress-linear>
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="details_list"
              :search="search"
              
            >
           <template v-slot:footer.page-text="props">
        <b>TotalAmount: {{gradndtotal}}</b>
       </template>
              <template v-slot:item.cost="{ item }">
                <v-icon>mdi-currency-inr</v-icon
                ><span style="font-size:18px">{{ item.cost }}</span>
              </template>
              <template v-slot:item.price="{ item }">
                <v-icon>mdi-currency-inr</v-icon
                ><span style="font-size:18px">{{ item.price }}</span>
              </template>
              <template v-slot:item.totalprice="{ item }">
                <v-icon>mdi-currency-inr</v-icon
                ><span style="font-size:18px">{{ item.totalprice }}</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </div>
    </section>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular:false,
    org_list: [],
    details_list: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    orgid: null,
    gradndtotal:0,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Title", value: "title" },
      { text: "Author", value: "author" },
      { text: "Publisher", value: "publisher" },
      { text: "No. of Books", value: "total_books" },
      { text: "Date of Entry", value: "entry_date" },
      // { text: "Discount Price", value: "price" },
       { text: "Cost", value: "cost" },
        { text: "totalcost", value: "totalprice" },
    ],
    single_: {
      "Sr. No.": "srno",
      Title: "title",
      Author: "author",
      Publisher: "publisher",
      "No. of Books": "total_books",
      "Date of Entry": "entry_date",
      // "Discount Price": "price",
       Cost: "cost",
       totalcost: "totalprice",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.circular = true;
      axios
        .post("/Management/getorglist")
        .then((res) => {
          if (res.data.msg == "200") {
            this.org_list = res.data.orglist;
           
            this.circular = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    getdetails() {
          if(this.orgid=='ALL')
      {

      this.circular=true
      axios
        .post("/Management/getAllTitleData")
        .then((res) => {
          if (res.data.msg == "200") {
              this.details_list = res.data.titledatalist;
              this.gradndtotal = res.data.gradndtotal;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
      }
      
      else{
      
      const data = {
        orgid: this.orgid,
      };
      this.circular=true
      axios
        .post("/Management/getTitleDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.details_list = res.data.titledatalist;
              this.gradndtotal = res.data.gradndtotal;
            this.circular = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
        }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
</style>
